<template>
  <!-- <Modal
    class-name="sub_modal"
    v-model="showModal"
    :mask-closable="false"
    width="500"
    :footer-hide="true"
  > -->
  <div class-name="sub_modal" :v-model="showModal" :mask-closable="false" :footer-hide="true">
    <!-- <div class="add_title">申请信息</div> -->
    <Form ref="formValidate" :model="formValidate" :rules="ruleValidate" label-position="top">
      <FormItem label="手机号" prop="mobile">
        <Input v-model="formValidate.mobile" placeholder="请输入手机号" maxlength="20"></Input>
      </FormItem>
      <FormItem label="名称" prop="name">
        <Input v-model="formValidate.name" placeholder="请输入姓名" maxlength="30"></Input>
      </FormItem>
      <FormItem label="身份证号码" prop="cardID">
        <Input v-model="formValidate.cardID" placeholder="请输入身份证号码" maxlength="18" type="number"></Input>
      </FormItem>
      <FormItem label="邮箱" prop="email">
        <Input v-model="formValidate.email" placeholder="请输入邮箱" maxlength="50"></Input>
      </FormItem>
      <FormItem label="公司名称" prop="company">
        <Input v-model="formValidate.company" placeholder="请输入公司" maxlength="50"></Input>
      </FormItem>
      <FormItem label="部门名称" prop="department">
        <Input v-model="formValidate.department" placeholder="请输入部门" maxlength="30"></Input>
      </FormItem>
      <div class="submit_group">
        <Button type="primary" @click="handleSubmit('formValidate')" style="width: 170px; background-image: linear-gradient(90deg, #5daafe 5%, #727aff 100%); border: 0px">确认申请</Button>
        <Button @click="handleReset('formValidate')" style="margin-left: 8px">取消</Button>
      </div>
    </Form>
  </div>
  <!-- </Modal> -->
</template>

<script>
  import { editAppRegister, getAppRegisterById } from "@/api/api.js";

  import Cookies from "js-cookie";
  import { getStore } from "@/libs/storage";
  export default {
    components: {},
    data() {
      var checkPhone = (rule, value, callback) => {
        if (!value) {
          return callback(new Error("手机号不能为空"));
        } else {
          const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
          if (reg.test(value)) {
            callback();
          } else {
            return callback(new Error("请输入正确的手机号"));
          }
        }
      };
      return {
        showModal: false,
        ruleSmsValidate: {
          mobile: [
            { required: true, trigger: "blur", message: "请输入手机号" },
            { validator: checkPhone, trigger: "blur" },
          ],
          name: [{ required: true, trigger: "blur", message: "请输入姓名" }],
          email: [{ required: true, trigger: "blur", message: "请输入邮箱地址" }],
        },
        formValidate: {
          name: "",
          cardID: "",
          mobile: "",
          company: "",
          email: "",
          department: "",
          username: "",
        },
        ruleValidate: {
          name: [{ required: true, message: "姓名不能为空", trigger: "blur" }],
          cardID: [
            { required: true, message: "身份证号码不能为空", trigger: "blur" },
            { validator: this.validID, trigger: "blur" },
          ],
          email: [
            { required: true, message: "邮箱不能为空", trigger: "blur" },
            { type: "email", message: "邮箱格式不正确", trigger: "blur" },
          ],
          mobile: [
            { required: true, message: "手机号不能为空", trigger: "blur" },
            { validator: checkPhone, trigger: "blur" },
          ],
        },
      };
    },
    methods: {
      handleSubmit(name) {
        let _this = this;
        let userInfo = Cookies.get("userInfo");
        this.formValidate.username = getStore("uid");
        if (getStore("uid").length < 2) {
          return;
        }
        this.$refs[name].validate((valid) => {
          if (valid) {
            let params = this.formValidate;

            // 根据res.result.birthday "1993-10-15" 匹配校验是否成年
            let birthYear = parseInt(params.cardID.slice(6, 10));
            let currentYear = new Date().getFullYear();
            let age = currentYear - birthYear >= 18;

            if (age !== true) {
              _this.$Message.error("请确认是否已成年");
              return;
            }
            // 提交数据
            let formData = new FormData();
            formData.append("name", "认证通过");
            formData.append("id_card", 1);
            formData.append("mobile", params.mobile);
            formData.append("company", params.company);
            formData.append("department", params.department);
            formData.append("username", getStore("uid"));
            formData.append("email", params.email);

            fetch(`https://zidv2.market.alicloudapi.com/idcard/VerifyIdcardv2?cardNo=${params.cardID}&realName=${params.name}`, {
              method: "GET",
              // params: checkData,
              headers: {
                Authorization: "APPCODE 950ee8edde9248a3829103ba787057fd",
                "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
              },
            })
              .then((res) => res.json())
              .then((res) => {
                if (res.result.isok !== true) {
                  if(res.error_code=="206501"){
                    return _this.$Message.error("暂时无法识别词身份证号码，请确认");
                  }
                  return _this.$Message.error("身份证号码与姓名不匹配");
                } else {
                  editAppRegister(formData).then((response) => {
                    if (response.success) {
                      _this.$emit("changeUser", { id: response.result.id });
                      _this.$Message.success("申请成功");
                      _this.showModal = false;
                    } else {
                      _this.$Message.error(response.message);
                    }
                  });
                }
              });
          } else {
            // this.$Message.error("Fail!");
          }
        });
      },
      handleReset(name) {
        // this.$refs[name].resetFields();
        this.$emit("changeUser", { id: "" });
        this.showModal = false;
      },

      async validID(rule, value, callback) {
        // 身份证号码为15位或者18位，15位时全为数字，18位前17位为数字，最后一位是校验位，可能为数字或字符X
        let reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
        if (reg.test(value)) {
        } else {
          callback(new Error("身份证号码不正确"));
        }
      },
    },
    mounted() {
      let userInfo = Cookies.get("userInfo");
      if (userInfo) {
        let scd = JSON.parse(userInfo).mobile;
        this.formValidate.mobile = scd;
        this.formValidate.username = getStore("uid");
      }
    },
  };
</script>

<style>
  .add_title {
    width: 100%;
    font-size: large;
    font-weight: bolder;
    margin-bottom: 0.2rem;
  }
  .submit_group {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .sub_modal {
    width: 100%;
  }
</style>
